import React from "react";
import PropTypes from "prop-types";
import maintanenceImage from "../ps-assets/img/service-maintenance.png" 
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer";
//import {withPrefix } from "gatsby"
//import Datetime from "react-datetime";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import notificationPageStyle from '../ps-assets/jss/notificationPageStyle';
import Layout from "ps-components/Layout/Layout";
class MaintanencePage extends React.Component {
    state = {
        aboveSchedule:
          "Dear Valued Customer's||We are under scheduled maintenance and system will not be available.",
        schedule: '31-March-2022 (Thursday) 11.55 PM to 01-April-2020 (Friday) 12.00 PM.',
        belowSchedule:
          'We apologize for any inconvenience getting caused.||Warm Regards,|Star Health and Allied Insurance Co Ltd',
      };
    render(){
        let { classes }= this.props
        let { aboveSchedule, schedule, belowSchedule } = this.state;
        let aboveScheduleLines = aboveSchedule.split('||');
        let scheduleLines = schedule && schedule.split('||');
        let belowScheduleLines = belowSchedule.split('||');
        return(
            <Layout>
             <GridContainer>
          <GridItem
            md={7}
            xs={12}
            sm={12}
            className={classes.maintenanceComp}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '30px',
            }}>
            <Card>
              <CardBody>
                <div style={{ paddingLeft: '10px', paddingBottom: '15px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      width='200'
                      height='200'
                      src={maintanenceImage}
                      alt='...'
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  {aboveScheduleLines &&
                    aboveScheduleLines.map((line, index) => (
                      <>
                        {line.split('|').map((l, i) => (
                          <div  key={i}>{l}</div>
                        ))}
                        {index !== aboveScheduleLines.length - 1 && <br />}
                      </>
                    ))}
                  {scheduleLines && (
                    <b>
                      <ul style={{ paddingInlineStart: '15px' }}>
                        {scheduleLines.map((line) => {
                          if (line !== '') {
                            return <li>{line}</li>;
                          }
                        })}
                      </ul>
                    </b>
                  )}
                  {belowScheduleLines &&
                    belowScheduleLines.map((line, index) => (
                      <>
                        {line.split('|').map((l) => (
                          <div key={index}>{l}</div>
                        ))}
                        <br />
                      </>
                    ))}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
            </Layout>
        )
    }
}
    
MaintanencePage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(notificationPageStyle)(MaintanencePage);