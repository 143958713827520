import {
    whiteColor,
    mlAuto,
    hexToRgb,
    primaryColor,
    title,
    // blackColor,
  } from 'assets/jss/material-kit-pro-react';
  import tooltip from 'assets/jss/material-kit-pro-react/tooltipsStyle';
  import imagesStyle from '@ps/star-ui/assets/jss/material-kit-pro-react/imagesStyles';
  
  const customHeaderLinksStyle = (theme) => ({
    ...imagesStyle,
    setWhiteBackground: {
      backgroundColor: 'white',
    },
    // list: {
    //   [theme.breakpoints.up('lg')]: {
    //     WebkitBoxAlign: 'center',
    //     MsFlexAlign: 'center',
    //     alignItems: 'center',
    //     WebkitBoxOrient: 'horizontal',
    //     WebkitBoxDirection: 'normal',
    //     MsFlexDirection: 'row',
    //     flexDirection: 'row',
    //   },
    //   [theme.breakpoints.down('md')]: {
    //     display: 'block',
    //     marginLeft: `10px !important`,
    //     marginTop: `15px !important`,
    //   },
    //   marginTop: '0px',
    //   display: 'flex',
    //   paddingLeft: '0',
    //   marginBottom: '0',
    //   listStyle: 'none',
    //   padding: '0',
    //   '& h6': {
    //     textTransform: 'capitalize',
    //     marginBottom: '0',
    //     fontSize: 'bold',
    //   },
    // },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('md')]: {
        '& ul': {
          maxHeight: '450px',
          overflow: 'hidden',
        },
        '& button': {
          margin: `0px !important`,
        },
        width: '100%',
        '&:not(:last-child)': {
          '&:after': {
            width: 'calc(100% - 30px)',
            content: '""',
            display: 'block',
            height: '1px',
            marginLeft: '15px',
            // backgroundColor: grayColor[14],
          },
        },
      },
    },
    listItemText: {
      padding: '0 !important',
    },
    navLink: {
      color: '#000 !important',
      position: 'relative',
      padding: '10px !important',
      paddingLeft: '15px !important',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
      '& span div:after': {
        content: "''",
        position: 'absolute',
        zIndex: '1',
        left: '50%',
        right: '50%',
        bottom: '0',
        backgroundColor: primaryColor[0],
        height: '4px',
        transitionProperty: 'left, right',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-out',
      },
      '&:hover span div:after': {
        left: '0',
        right: '0',
      },
      '& .fab,& .far,& .fal,& .fas,& .material-icons': {
        position: 'relative',
        top: '2px',
        marginTop: '-4px',
        marginRight: '4px',
        marginBottom: '0px',
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        marginBottom: '8px',
        marginTop: '8px',
        textAlign: 'left',
        '& > span:first-child': {
          justifyContent: 'flex-start',
        },
        '&:hover span div:after': {
          content: '',
          left: '51%',
          right: '51%',
        },
      },
      '& svg': {
        marginRight: '3px',
        width: '20px',
        height: '20px',
      },
    },
    navLinkJustIcon: {
      '& .fab,& .far,& .fal,& .fas,& .material-icons': {
        marginRight: '0px',
      },
      '& svg': {
        marginRight: '0px',
      },
    },
    navButton: {
      position: 'relative',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        marginBottom: '5px',
        marginTop: '5px',
        textAlign: 'left',
        '& > span:first-child': {
          justifyContent: 'flex-start',
        },
      },
      '& $icons': {
        marginRight: '3px',
      },
    },
    notificationNavLink: {
      color: 'inherit',
      padding: '0.9375rem',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
    },
    registerNavLink: {
      position: 'relative',
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      textDecoration: 'none',
      margin: '0px',
      display: 'inline-flex',
    },
    navLinkActive: {
      '&, &:hover, &:focus,&:active ': {
        color: 'inherit',
        backgroundColor: `rgba(${hexToRgb(whiteColor)}, 0.1)`,
      },
    },
    icons: {
      width: '20px',
      height: '20px',
      marginRight: '14px',
    },
    dropdownIcons: {
      width: '24px',
      height: '24px',
      marginRight: '14px',
      opacity: '0.5',
      marginTop: '-4px',
      top: '1px',
      verticalAlign: 'middle',
      fontSize: '24px',
      position: 'relative',
    },
    socialIcons: {
      position: 'relative',
      fontSize: '1.25rem',
      maxWidth: '24px',
    },
    dropdownLink: {
      '&,&:hover,&:focus': {
        color: 'inherit',
        textDecoration: 'none',
        display: 'flex',
        padding: '0.75rem 1.25rem 0.75rem 0.75rem',
      },
    },
    ...tooltip,
    marginRight5: {
      marginRight: '5px',
    },
    collapse: {
      [theme.breakpoints.up('lg')]: {
        display: 'flex !important',
        MsFlexPreferredSize: 'auto',
        flexBasis: 'auto',
      },
      WebkitBoxFlex: '1',
      MsFlexPositive: '1',
      flexGrow: '1',
      WebkitBoxAlign: 'center',
      MsFlexAlign: 'center',
      alignItems: 'center',
    },
    mlAuto,
    subLabel: {
      color: '#9e9e9e',
    },
    promotionLabel: {
      color: primaryColor[0],
      fontWeight: 'bold',
    },
    divider: {
      borderRight: 'solid thin #ddd',
      height: 'calc(100% - 30px)',
      marginTop: '10px',
      paddingRight: '0',
    },
    customSvg: {
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
    customLabel: {
      marginTop: `0px`,
      marginBottom: `0px`,
      textTransform: `capitalize`,
    },
  
    svgStyle: {
      boxShadow: 'none !important',
      float: 'left',
      '& svg': {
        height: '25px !important',
        width: '25px !important',
      },
      '& path': {
        fill: '#aaa !important',
      },
    },
    textNameNav: {
      marginLeft: '10px',
      fontWeight: 'bold',
    },
    title: {
      ...title,
      marginBottom: `0px !important`,
      position: 'relative',
      marginTop: '0px !important',
      minHeight: '32px',
      textDecoration: 'none',
    },
    profile: {
      textAlign: 'center',
      backgroundColor: 'white',
      paddingTop: 70,
      paddingBottom: 20,
      '& img': {
        maxWidth: '160px',
        width: '100%',
        margin: '0 auto',
        transform: 'translate3d(0, -50%, 0)',
      },
    },
    imagecenter: {
      marginLeft: '97px',
  
      '@media (max-width:500px)': {
        marginLeft: '0px',
      },
    },
    maintenanceComp: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '100px !important',
      },
    },
  });
  
  export default customHeaderLinksStyle;
  